/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="17"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="General Donation HI, USA offline" />';
const PageContent = React.memo(PageContentNonMemoized);

const HiUSAOffline = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("HI-usa-general-donation-offline", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="General donations to Heartfulness institute, USA Offline - Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>
          General donations to Heartfulness institute, USA Offline
        </h3>
        <p>
          All donations to the Heartfulness Institute are voluntary. Donated
          funds are used to:
        </p>
        <ul>
          <li>
            Host conventions and seminars that feature motivational speakers and
            artists to promote spirituality, meditation and related lifestyle
            choices.
          </li>
          <li>
            Develop marketing and informational materials and publications that
            support the message of Heartfulness.
          </li>
          <li>
            Develop and support meditation-based education, research, and
            training courses.
          </li>
          <li>Establish and support Heartfulness Meditation Centers.</li>
        </ul>
        <br />
        Please contact{" "}
        <a href="mailto:us.treasurer@heartfulnessinstitute.org">
          us.treasurer@heartfulnessinstitute.org
        </a>{" "}
        for questions about HI donations.
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default HiUSAOffline;
